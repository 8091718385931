
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Location } from 'vue-router/types/router'
  import { Route } from 'vue-router'
  import { Link } from '@/store/app/state'
  import { mapActions, mapMutations } from 'vuex'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
    ...mapActions('app', ['setRole']),
    ...mapMutations('app', ['setProcess']),
  },
})
  export default class List extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: Array<Link>;
  @Prop({ type: String, default: '' }) search!: string;
  @Prop({ type: Boolean, default: false }) isMini!: boolean;
  selected = -1;

  setProcess!: (payload: any) => void
  setRole!: (payload: string) => void
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  mounted (): void {
    const selected = this.items.findIndex(
      item => item.route.path === this.$route.path || item.route.name === this.$route.name
    )

    if (selected !== -1 && this.items?.[selected]) {
      this.setRole(this.items[selected].slug)
      this.selected = selected
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  async onChangeRoute (val: Route): Promise<void> {
    const role = val.path?.split('/')?.slice(2, 3)[0] // Obtiene el rol de la URL
    const pathName = role?.split('_') // Divide el rol en partes
    let processName

    const selected = this.items?.findIndex((item: Link) => {
      // Verifica si alguna parte de pathName está incluida en el path o name del item
      const matchPath = pathName?.some(part => item.slug?.includes(part))
      const matchName = pathName?.some(part => item.slug?.includes(part))

      return matchPath || item.route.path === val.path || matchName || item.route.name === val.name
    })
    if (pathName?.length > 2) {
      processName = pathName[1] + '_' + pathName[2]
    } else {
      processName = pathName?.[1]
    }

    if (processName) {
      const process = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: processName } },
        force: true,
      })
      this.setProcess(process[0])
    }

    if (selected !== -1 && this.items[selected]) {
      this.setRole(this.items[selected].slug)
      this.selected = selected
    }
  }

  goTo (val: Location): void {
    const { path, name } = val
    this.$router.push(path || { name }).catch(() => {
    })
  }
  }
